@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
	--background-dark: hsl(0, 0%, 5%);
	--cs-dark-grey: hsla(0, 0%, 4%, 0.5);
	--cs-orange: hsl(32, 100%, 50%);
	--cs-blue: hsl(212, 100%, 50%);
	--cs-white: hsl(0, 0%, 90%);
	--cs-d-orange: hsl(32, 100%, 20%);
	--cs-ft-heading: 'Roboto Slab', serif;
	--cs-ft: 'Quicksand', sans-serif;
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

h1,
h2,
h3 {
	font-family: var(--cs-ft-heading);
	color: var(--cs-orange);
}

h1 {
	font-size: 2.8em;
	font-weight: 300;
}

h2 {
	font-size: 2em;
	font-weight: 300;
	margin: 0.4em 0;
}

h3 {
	color: black;
}

h2::after {
	content: '';
	border-bottom: 1px solid var(--cs-d-orange);
	display: block;
}

p {
	font-family: var(--cs-ft);
	color: var(--cs-white);
	font-size: 1.1em;
	margin: 0.5em 0;
	line-height: 1.5em;
	text-shadow: 1px 1px 1px black;
}

label {
	font-family: var(--cs-ft);
	color: rgb(187, 187, 187);
	font-size: 1.0em;
	padding-left: 0.4rem;
}

a,
a:visited {
	color: var(--cs-orange);
	text-decoration: none;
	transition: transform 150ms ease-in;
}

a:hover {
	color: hsl(32, 100%, 60%);
	transition: transform 150ms ease-in;
}

a::after {
	display: block;
	position: relative;
	top: 0;
	left: 0;
	content: '';
	width: 100%;
	height: 1px;
	background: var(--cs-orange);
	transform:scaleX(0);
	transition: transform 150ms ease-in;
}

a:hover::after {
	transform:scaleX(1);
	background: hsl(32, 100%, 60%);
	transition: transform 150ms ease-in;
}


/**
  *	Heading
  * 
**/

@keyframes fade-in {
	0% {
	  opacity: 0;
	  transform: translateX(200px);
	}
	100% {
	  opacity: 1;
	  transform: translateX(0px);
	}
  }

@keyframes fade-out {
	0% {
	  opacity: 1;
	  transform: translateX(0px);
	}
	100% {
	  opacity: 0;
	  transform: translateX(200px);
	}
}

#nav-big {
	display: none;
}

#cd-top {
	height: 40px;
	width: auto;
}

#clicker {
	width: auto;
}

.svg-orange {
	fill: var(--cs-orange);
}

.svg-black {
	fill: black;
}

.landing {
	overflow: hidden;
	max-width: 100vw;
}

#nav-bar {
	position: relative;
	right: 0px; 
	top: 0;
	padding: 1.5em 3em;
	background-color: var(--cs-orange);
	font-family: var(--cs-ft);
	transform: translateX(200px);
	opacity: 0;
	width: 10rem;
}

.nav-visible {
	left: calc(100vw - 10rem);
	top: 0px;
	animation: fade-in 500ms both;
	opacity: 1;
}

.nav-hidden {
	left: calc(100vw - 10rem);
	top: 0px;
	animation: fade-out 500ms both;
	opacity: 0;
}

.heading {
	display: flex;
	justify-content: space-between;
	padding: 1rem 2rem;
	transition-duration: 500ms;
	align-items: center;
}

.heading.orange {
	background-color: var(--cs-orange);
	transition-duration: 500ms;
}

nav a,
nav a:visited {
	font-weight: 800;
	color: black;
}

nav ul {
	list-style: none;
}

nav li {
	margin: 2em 0;
}




/**
  *	Introduction
  * 
**/
@keyframes hello {
	0% {
	  opacity: 0;
	  transform: translateX(calc(50vw - 100%)) translateY(80px);
	}
	40% {
		opacity: 1;
		transform: translateX(calc(50vw - 100%)) translateY(0px);
	}
	100% {
	  opacity: 1;
	  transform: translateX(0px);
	}
  }

@keyframes there {
	0% {
	  opacity: 0;
	  transform: translateY(80px);
	}
	100% {
	  opacity: 1;
	  transform: translateY(0px);
	}
  }

.hi {
	transform: translateX(calc(50vw - 100%)) translateY(80px);
	display: inline-block;
	animation: hello 2000ms 400ms forwards;
	opacity: 0;
}

.imchris {
	transform: translateY(80px);
	display: inline-block;
	animation: there 600ms 1800ms forwards;
	opacity: 0;
}

.intro {
	display: flex;
	flex-direction: column;
	padding: 2rem 2rem;
}

.intro p {
	transform: translateY(80px);
	animation: there 600ms 2400ms forwards;
	opacity: 0;
	max-width: 600px;
}

.button-group {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 1.2em;
	padding: 1.5rem 0;
	transform: translateY(80px);
	animation: there 600ms 2800ms forwards;
	opacity: 0;

}

.btn {
	padding: 0.5rem 1rem;
	border: 1px solid; 
	border-radius: 3px;
	text-decoration: none;
	font-family: var(--cs-ft);
	font-weight: 500;
	color: white;
	transition-duration: 100ms;
}

.btn-primary,
.btn-primary:visited {
	background-color: var(--cs-blue);
	border-color: hsl(212, 100%, 60%);
	color: white;
}

.btn-secondary,
.btn-secondary:visited {
	color: var(--cs-white);
}

.btn-tri {
	color: black;
	border: none;
}

.btn-primary:hover::after,
.btn-secondary:hover::after,
.btn-tri:hover::after {
	display: none;
}

.btn:hover {
	transform:translateY(-3px);
	transition-duration: 100ms;
}

/**
  *	About
  * 
**/
.about {
	display: flex;
	flex-direction: column;
	padding: 2rem 0;
}

.about h2,
.about p {
	padding: 0 2rem;
}

.about-form {
	display: flex;
	flex-direction: column;
	margin-top: 1.5rem;
	padding: 1.5rem 2rem;
	gap: 1rem;
	background-color: var(--cs-dark-grey);
}

.form-upper {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}

.form-box {
	width: 100%;
}

.form-button-area {
	display: flex;
	justify-content: flex-end;
}

.form-contact-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem 0;
	gap: 0.5em;
}

.form-contact {
	display: flex;
	align-items: center;
	color: var(--cs-white);
	font-family: var(--cs-ft);
}

.form-contact svg {
	transform: scale(60%);
	fill: var(--cs-white);
}

input,
textarea {
	background-color: var(--background-dark);
	border: 1px solid hsl(32, 100%, 19%);
	padding: 0.2rem 0.4rem;
	margin-top: 0.2rem;
	margin-bottom: 0.5rem;
	font-family: var(--cs-ft);
	color: var(--cs-white);
	font-size: 1.1em;
	width: 100%;
}

input:focus,
textarea:focus {
	outline: 1px solid var(--cs-orange);
}

.btn-tri {
	background-color: var(--cs-orange);
	font-family: var(--cs-ft);
	font-weight: 800;
	font-size: 1.1em;
}


/**
  *	Work Section
  * 
**/

img {
	width: 100%;
	height: auto;
}

.work-header {
	margin: 1.5rem 1em;
}

.work {
	margin: 0 1rem;
}

.work-card {
	background-color: var(--cs-dark-grey);
	margin: 2rem 0;
}

.work-heading-bg { 
	grid-area: 1 / 1 / 2 / 2; 
	background-color: var(--cs-orange);
}

.work-img { 
	grid-area: 2 / 1 / 3 / 2; 
	max-height: 200px;
	overflow: hidden;
}

.work-git { 
	display: flex;
	justify-content: center;
	grid-area: 3 / 2 / 4 / 4; 
	color: hsl(0, 0%, 63%);
	padding: 1rem 0;
}

.work-heading { 
	grid-area: 1 / 2 / 2 / 3; 
	background-color: var(--cs-orange);
	padding: 0.5rem 1rem;
	box-shadow: 0 2px 4px 0px rgb(5, 5, 5);
}

.work-body { 
	grid-area: 2 / 2 / 3 / 4; 
	padding: 0.5rem 1rem;
}

.work-components { 
	grid-area: 3 / 1 / 4 / 2; 
	padding: 0.5rem 1rem;
} 

.work-components ul {
	display: flex;
	list-style: none;
	gap: 1.5rem;
	color: hsl(0, 0%, 63%);
	font-size: 0.9em;
}


/**
  *	Blog Section
  * 
**/

.blog {
	margin-top: 4rem;
}

.blog-heading h2 {
	margin: 0 1em;
}

.blog-card {
	background-color: var(--cs-dark-grey);
	margin: 2rem 1rem;
}

.blog-card-title {
	background-color: var(--cs-orange);
	padding: 0.5rem 1rem;
	box-shadow: 0 2px 4px 0px rgb(5, 5, 5);
}

.blog-body {
	padding: 0 0.5rem;
	margin: 0 0.5rem;
}

.blog-link {
	padding: 0.5rem 2rem;
	display: flex;
	justify-content: right;
}


/**
  *	Footer
  * 
**/
footer {
	background-color: var(--cs-orange);
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 2em 2em;
}

footer ul {
	list-style: none;
}

li {
	display: flex;
	align-items: center;
}

.footer-left svg {
	height: 50px;
	width: auto;
}

.footer-right svg {
	transform: scale(60%);
}

footer a,
footer a:visited {
	color: black;
	text-decoration: none;
	transition: transform 150ms ease-in;
}

footer a:hover {
	color: black;
	transition: transform 150ms ease-in;
}

footer a::after {
	display: block;
	position: relative;
	top: 0;
	left: 0;
	content: '';
	width: 100%;
	height: 1px;
	background: black;
	transform:scaleX(0);
	transition: transform 150ms ease-in;
}

footer a:hover::after {
	transform:scaleX(1);
	background: black;
	transition: transform 150ms ease-in;
}




@media only screen and (min-width: 600px) {
	/**
	*	Heading
	* 
	**/

	#clicker {
		display: none;
	}

	nav {
		position: relative;
		top: 0;
	}

	#nav-bar {
		display: none;
	}

	#nav-big {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	#nav-big li {
		display: inline-block;
		margin-left: 4em;
	}

	.heading {
		display: flex;
		justify-content: flex-start;
		padding-left: 10%;
		padding-right: 10%;
	}

	.heading a,
	.heading a:visited {
		color: var(--cs-orange);
		font-weight: 600;
	}


	/**
	*	Introduction
	* 
	**/
	@keyframes hello-600 {
		0% {
		  opacity: 0;
		  transform: translateX(calc(45vw - 100%)) translateY(80px);
		}
		40% {
			opacity: 1;
			transform: translateX(calc(45vw - 100%)) translateY(0px);
		}
		100% {
		  opacity: 1;
		  transform: translateX(0px);
		}
	  }

	.intro {
		padding-left: 10%;
		padding-right: 10%;
	}

	.button-group {
		justify-content: flex-start;
	}

	.hi {
		animation: hello-600 2000ms 400ms forwards;
	}


	/**
	*	About
	* 
	**/
	
	.about h2,
	.about p {
		padding-left: 10%;
		padding-right: 10%;
	}

	.about-form {
		padding-left: 10%;
		padding-right: 10%;
	}

	.form-contact-info {
		display: flex;
		flex-direction: row;
		gap: 4em;
	}

	.form-upper {
		display: flex;
		flex-wrap: nowrap;
	}

	/**
	*	Work Section
	* 
	**/
	.work-header {
	}

	.work {
		margin-left: 8%;
		margin-right: 8%;
	}

	/**
	*	Blog Section
	* 
	**/
	.blog-heading h2 {
		margin-left: 10%;
		margin-right: 10%;
	}

	.blog-card {
		margin-left: 8%;
		margin-right: 8%;
	}

	/**
	*	Footer
	* 
	**/
	footer {
		padding-left: 10%;
		padding-right: 10%;
	}
}




@media only screen and (min-width: 800px) {
	/**
	*	Heading
	* 
	**/

	#clicker {
		display: none;
	}

	nav {
		position: relative;
		top: 0;
	}

	#nav-bar {
		display: none;
	}

	#nav-big {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	#nav-big li {
		display: inline-block;
		margin-left: 4em;
	}

	.heading {
		display: flex;
		justify-content: flex-start;
		padding-left: 15%;
		padding-right: 15%;
	}

	.heading a,
	.heading a:visited {
		color: var(--cs-orange);
		font-weight: 600;
	}


	/**
	*	Introduction
	* 
	**/
	@keyframes hello-800 {
		0% {
		  opacity: 0;
		  transform: translateX(calc(37vw - 100%)) translateY(80px);
		}
		40% {
			opacity: 1;
			transform: translateX(calc(37vw - 100%)) translateY(0px);
		}
		100% {
		  opacity: 1;
		  transform: translateX(0px);
		}
	  }

	.intro {
		padding-left: 15%;
		padding-right: 15%;
	}

	.button-group {
		justify-content: flex-start;
	}

	.hi {
		animation: hello-800 2000ms 400ms forwards;
	}


	/**
	*	About
	* 
	**/
	
	.about h2,
	.about p {
		padding-left: 15%;
		padding-right: 15%;
	}

	.about-form {
		padding-left: 15%;
		padding-right: 15%;
	}

	.form-contact-info {
		display: flex;
		flex-direction: row;
		gap: 4em;
	}

	.form-upper {
		display: flex;
		flex-wrap: nowrap;
	}

	/**
	*	Work Section
	* 
	**/
	.work-header {
		width: 100%;
	}

	.work {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin-left: 13%;
		margin-right: 13%;
	}

	.work-img {
		max-height: 15vw;
	}

	.work-card {
		width: 45%;
	}

	/**
	*	Blog Section
	* 
	**/
	.blog-heading h2 {
		margin-left: 15%;
		margin-right: 15;
	}

	.blog-card {
		margin-left: 13%;
		margin-right: 13%;
	}

	/**
	*	Footer
	* 
	**/
	footer {
		padding-left: 15%;
		padding-right: 15%;
	}
}





@media only screen and (min-width: 1200px) {
	h2::after {
		display: none;
	}

	/**
	*	Heading
	* 
	**/

	#clicker {
		display: none;
	}

	nav {
		position: relative;
		top: 0;
	}

	#nav-bar {
		display: none;
	}

	#nav-big {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	#nav-big li {
		display: inline-block;
		margin-left: 4em;
	}

	.heading {
		display: flex;
		justify-content: flex-start;
		padding-left: 15%;
		padding-right: 15%;
	}

	.heading a,
	.heading a:visited {
		color: var(--cs-orange);
		font-weight: 600;
	}

	.landing {
		height: 100vh;
	}


	/**
	*	Introduction
	* 
	**/
	@keyframes hello-800 {
		0% {
		  opacity: 0;
		  transform: translateX(calc(37vw - 100%)) translateY(50vh);
		}
		40% {
			opacity: 1;
			transform: translateX(calc(37vw - 100%)) translateY(0px);
		}
		100% {
		  opacity: 1;
		  transform: translateX(0px);
		}
	  }

	.intro {
		margin-top: 30vh;
		padding-left: 15%;
		padding-right: 15%;
	}

	.button-group {
		justify-content: flex-start;
	}

	.hi {
		animation: hello-800 2000ms 400ms forwards;
	}


	/**
	*	About
	* 
	**/
	
	.about h2,
	.about p {
		padding-left: 0;
		padding-right: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.about {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows:0.2fr 1fr;
		grid-column-gap: 2em;
		grid-row-gap: 0px; 
		padding: 0 15%;
	}

	.about-header {
		grid-area: 1 / 1 / 2 / 3;
		margin-top: 4em;
		margin-bottom: 1.5rem;
	}

	.about-body {
		grid-area: 2 / 1 / 3 / 2;
		padding: 0;
		margin: 0;
		padding-top: 0.7em;
	}

	.about-form {
		grid-area: 2 / 2 / 3 / 3;
		padding: 1em;
		margin: 0;

	}

	.form-contact-info {
		display: flex;
		flex-direction: row;
		gap: 4em;
		padding: 0.5em 0;
	}

	.form-upper {
		display: flex;
		flex-wrap: nowrap;
	}

	.decoration-box {
		z-index: -1;
		position: relative;
		top: -22px;
		left: -70px;
	}

	.decor-top {
		border-left: 2px solid var(--cs-orange);
		border-top: 2px solid var(--cs-orange);
		border-right: 1px solid hsla(32, 100%, 20%, 0.4);
		height: 120px;
		width: 220px;
	}

	.decor-bottom {
		position: relative;
		left: 110px;
		border-right: 1px solid hsla(32, 100%, 20%, 0.4);
		border-bottom: 1px solid hsla(32, 100%, 20%, 0.4);
		height: 250px;
		width: 110px;
		margin-bottom: -370px;
	}

	/**
	*	Work Section
	* 
	**/
	.decoration-box-work {
		z-index: -1;
		position: relative;
		top: -22px;
		left: 0px;
	}

	.decor-top-work {
		border-left: 0px solid var(--cs-orange);
		border-top: 2px solid var(--cs-orange);
		border-right: 1px solid hsla(32, 100%, 20%, 0.4);
		height: 1350px;
		width: 880px;
	}

	.decor-bottom-work {
		position: relative;
		left: 700px;
		top: 25px;
		border-right: 1px solid hsla(32, 100%, 20%, 0.4);
		border-bottom: 0x solid hsla(32, 100%, 20%, 0.4);
		height: 250px;
		width: 180px;
		margin-bottom: -1590px;
	}

	.work-header {
		width: 100%;
		margin: 0 2rem;
	}

	.work {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin-left: 13%;
		margin-right: 13%;
		margin-top: 10rem;
	}

	.work-heading-bg {
		grid-area: 1 / 1 / 2 / 2;
	}

	.work-heading {
		grid-area: 1 / 2 / 2 / 3;
		z-index: -1;
		padding-left: 1rem;
	}

	.work-heading h3 {
		padding-left: 1rem;
	}

	.work-img {
		object-fit:cover;
		grid-area: 2 / 1 / 3 / 2;
		background-color: var(--cs-dark-grey);
		height: 100%;
		max-height: 220px;
	}

	.work-img img {
		height: auto;
	}

	.work-body {
		grid-area: 2 / 2 / 3 / 4;
		background-color: var(--cs-dark-grey);
		padding: 1em 2em;
	}

	.work-git {
		grid-area: 3 / 1 / 4 / 2;
		background-color: var(--cs-dark-grey);
		justify-content: flex-end;
	}

	.work-components {
		grid-area: 3 / 2 / 4 / 4;
		background-color: var(--cs-dark-grey);
		display: flex;
		align-items: center;
		padding: 1rem 2rem;
	}

	.work-card {
		width: 90%;
		display: grid;
		grid-template-columns: 1.75fr repeat(2, 1fr) max-content;
		grid-template-rows: 0.2fr 2fr 0.3fr max-content;
		grid-column-gap: 0px;
		grid-row-gap: 0px; 
		background-color: transparent;
	}

	/**
	*	Blog Section
	* 
	**/
	.blog {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
		gap: 2em;
		margin: 4em 15%;
	}

	.blog-heading {
		width: 100%;
	}

	.blog-heading h2 {
		margin-left: 0;
		margin-right: 0;
	}

	.blog-card {
		margin-left: 0;
		margin-right: 0;
		width: 30%;
	}

	.decoration-box-blog {
		z-index: -1;
		position: relative;
		top: -10px;
		left: -65px;
	}

	/**
	*	Footer
	* 
	**/
	footer {
		padding-left: 15%;
		padding-right: 15%;
	}
}


@media only screen and (min-width: 1600px) {
	h2::after {
		display: none;
	}

	/**
	*	Heading
	* 
	**/

	#clicker {
		display: none;
	}

	nav {
		position: relative;
		top: 0;
	}

	#nav-bar {
		display: none;
	}

	#nav-big {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	#nav-big li {
		display: inline-block;
		margin-left: 4em;
	}

	.heading {
		display: flex;
		justify-content: flex-start;
		padding-left: 20%;
		padding-right: 20%;
	}

	.heading a,
	.heading a:visited {
		color: var(--cs-orange);
		font-weight: 600;
	}

	.landing {
		height: 100vh;
	}


	/**
	*	Introduction
	* 
	**/
	@keyframes hello-800 {
		0% {
		  opacity: 0;
		  transform: translateX(calc(30vw - 100%)) translateY(50vh);
		}
		40% {
			opacity: 1;
			transform: translateX(calc(30vw - 100%)) translateY(0px);
		}
		100% {
		  opacity: 1;
		  transform: translateX(0px);
		}
	  }

	.intro {
		margin-top: 30vh;
		padding-left: 20%;
		padding-right: 20%;
	}

	.button-group {
		justify-content: flex-start;
	}

	.hi {
		animation: hello-800 2000ms 400ms forwards;
	}


	/**
	*	About
	* 
	**/
	
	.about h2,
	.about p {
		padding-left: 0;
		padding-right: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.about {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows:0.2fr 1fr;
		grid-column-gap: 2em;
		grid-row-gap: 0px; 
		padding: 0 20%;
	}

	.about-header {
		grid-area: 1 / 1 / 2 / 3;
		margin-top: 4em;
		margin-bottom: 1.5rem;
	}

	.about-body {
		grid-area: 2 / 1 / 3 / 2;
		padding: 0;
		margin: 0;
		padding-top: 0.7em;
	}

	.about-form {
		grid-area: 2 / 2 / 3 / 3;
		padding: 1em;
		margin: 0;

	}

	.form-contact-info {
		display: flex;
		flex-direction: row;
		gap: 4em;
		padding: 0.5em 0;
	}

	.form-upper {
		display: flex;
		flex-wrap: nowrap;
	}

	.decoration-box {
		z-index: -1;
		position: relative;
		top: -22px;
		left: -70px;
	}

	.decor-top {
		border-left: 2px solid var(--cs-orange);
		border-top: 2px solid var(--cs-orange);
		border-right: 1px solid hsla(32, 100%, 20%, 0.4);
		height: 120px;
		width: 220px;
	}

	.decor-bottom {
		position: relative;
		left: 110px;
		border-right: 1px solid hsla(32, 100%, 20%, 0.4);
		border-bottom: 1px solid hsla(32, 100%, 20%, 0.4);
		height: 280px;
		width: 110px;
		margin-bottom: -400px;
	}

	/**
	*	Work Section
	* 
	**/
	.decoration-box-work {
		z-index: -1;
		position: relative;
		top: -22px;
		left: 0px;
	}

	.decor-top-work {
		border-left: 0px solid var(--cs-orange);
		border-top: 2px solid var(--cs-orange);
		border-right: 1px solid hsla(32, 100%, 20%, 0.4);
		height: 500px;
		width: 880px;
		margin-bottom: -470px;
	}

	.decor-bottom-work {
		display: none;
		position: relative;
		left: 700px;
		top: 25px;
		border-right: 1px solid hsla(32, 100%, 20%, 0.4);
		border-bottom: 0x solid hsla(32, 100%, 20%, 0.4);
		height: 50px;
		width: 180px;
		margin-bottom: -1490px;
	}

	.work-header {
		width: 100%;
		margin: 0 2rem;
	}

	.work {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin-left: 10%;
		margin-right: 10%;
		margin-top: 10rem;
	}

	.work-heading-bg {
		grid-area: 1 / 1 / 2 / 2;
	}

	.work-heading {
		grid-area: 1 / 2 / 2 / 4;
		z-index: -1;
		padding-left: 1rem;
	}

	.work-heading h3 {
		padding-left: 1rem;
	}

	.work-img {
		object-fit:cover;
		grid-area: 2 / 1 / 4 / 2;
		background-color: var(--cs-dark-grey);
		max-height: 245px;
	}

	.work-img img {
		height: auto;
	}

	.work-body {
		grid-area: 2 / 2 / 4 / 5;
		background-color: var(--cs-dark-grey);
		padding: 1em 2em;
	}

	.work-git {
		grid-area: 4 / 1 / 5 / 2;
		background-color: var(--cs-dark-grey);
		justify-content: flex-end;
	}

	.work-components {
		grid-area: 4 / 2 / 5 / 5;
		background-color: var(--cs-dark-grey);
		display: flex;
		align-items: center;
		padding: 1rem 2rem;
	}

	.work-card {
		width: 48%;
		display: grid;
		grid-template-columns: 1.75fr repeat(3, 1fr) max-content;
		grid-template-rows: 0.2fr 2fr 0.3fr max-content;
		grid-column-gap: 0px;
		grid-row-gap: 0px; 
		background-color: transparent;
	}

	/**
	*	Blog Section
	* 
	**/
	.blog {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
		gap: 2em;
		margin: 10em 20%;
	}

	.blog-heading {
		width: 100%;
	}

	.blog-heading h2 {
		margin-left: 0;
		margin-right: 0;
	}

	.blog-card {
		margin-left: 0;
		margin-right: 0;
		width: 30%;
	}

	.decoration-box-blog {
		z-index: -1;
		position: relative;
		top: -10px;
		left: -65px;
	}

	/**
	*	Footer
	* 
	**/
	footer {
		padding-left: 20%;
		padding-right: 20%;
	}

	footer a {
		color: black;
	}

	footer a:hover {
		color:black;
		text-decoration-color: black;
	}
}